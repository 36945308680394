/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Image } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Svatební barvy"} description={"Svatební barvy "}>
        <SiteHeader />

        <Column className="pb--10 pl--25 pr--25 pt--10" name={"prazdna_sekcee"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1 --full mt--10 pb--10" columns={"1"} fullscreen={true}>
            
            <ColumnWrapper style={{"paddingBottom":6}}>
              
              <Title className="title-box fs--72 mt--06" style={{"maxWidth":795,"marginTop":null,"paddingLeft":0,"marginBottom":54,"paddingBottom":0}} content={"<span style=\"color: var(--color-custom-1--15);\"><br>Svatební barvy a dress code</span>"}>
              </Title>

              <Text className="text-box text-box--center ff--3" style={{"maxWidth":429,"marginTop":0,"paddingTop":1}} content={"Naše svatba se bude odehrávat v podhůří Beskyd v měsíci září. Proto jsme zvolili barvy, které budou odrážet krajinu okolí.<br>A co na sebe ?<br>Cokoliv , v čem se budete cítit dobře a pohodlně.<br><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--l12w pb--60" name={"fisdy6k89jq"} style={{"paddingTop":43}}>
          
          <ColumnWrap className="column__flex --center el--4" columns={"4"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/31204/851beb00eafe46df9afdb3165a3819b9_e=80x80x623x467_s=350x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":606}} srcSet={"https://cdn.swbpg.com/t/31204/851beb00eafe46df9afdb3165a3819b9_e=80x80x623x467_s=350x_.png 350w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/31204/e3803e29a61f405cbcb1f2ae6337b15c_e=50x50x400x300_s=350x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":606}} srcSet={"https://cdn.swbpg.com/t/31204/e3803e29a61f405cbcb1f2ae6337b15c_e=50x50x400x300_s=350x_.png 350w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/31204/ed92959c7a8f4a2a8c19327887f792fa_e=50x50x400x300_s=350x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/31204/ed92959c7a8f4a2a8c19327887f792fa_e=50x50x400x300_s=350x_.png 350w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/31204/e9f4145168744875a3b2f1ef37ff205f_e=72x59x576x432_s=350x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/31204/e9f4145168744875a3b2f1ef37ff205f_e=72x59x576x432_s=350x_.png 350w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/31204/28a35b9e2210476a9f1b83b0158b6ac2_e=192x108x1296x972_s=660x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/31204/28a35b9e2210476a9f1b83b0158b6ac2_e=192x108x1296x972_s=350x_.png 350w, https://cdn.swbpg.com/t/31204/28a35b9e2210476a9f1b83b0158b6ac2_e=192x108x1296x972_s=660x_.png 660w, https://cdn.swbpg.com/t/31204/28a35b9e2210476a9f1b83b0158b6ac2_e=192x108x1296x972_s=860x_.png 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/31204/80509081048a40909911db074cda5f97_e=198x164x1581x1184_s=860x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/31204/80509081048a40909911db074cda5f97_e=198x164x1581x1184_s=350x_.png 350w, https://cdn.swbpg.com/t/31204/80509081048a40909911db074cda5f97_e=198x164x1581x1184_s=660x_.png 660w, https://cdn.swbpg.com/t/31204/80509081048a40909911db074cda5f97_e=198x164x1581x1184_s=860x_.png 860w, https://cdn.swbpg.com/t/31204/80509081048a40909911db074cda5f97_e=198x164x1581x1184_s=1400x_.png 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/31204/4804893861204c57a7df5b58a31eba5e_e=71x60x566x425_s=350x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/31204/4804893861204c57a7df5b58a31eba5e_e=71x60x566x425_s=350x_.png 350w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/31204/b64adf6b2dda4066aa122c9b9d7c461e_e=72x59x576x432_s=350x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/31204/b64adf6b2dda4066aa122c9b9d7c461e_e=72x59x576x432_s=350x_.png 350w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}